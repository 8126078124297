'use strict';
(function (window, $) {
    var $loginForm = $('form.jwsdw-login-form'),
        $loginErrorMessage = $loginForm.find('.jws-formErrorMessage'),
        $loginNotificationErrorMessage = $loginForm.find('.jwsdw-notificationFormErrorMessage'),
        $loginButton = $loginForm.find('.jwsdw-accordionForm-listItem-submit'),
        $loginButtonText = $loginButton.find('.jwsdw-buttonText'),
        $loginSpinner = $loginButton.find('.jwsdw-spinner'),
        $loginPickerForm = $('#jwsdw-loginPicker-form'),
        $loginPickerErrorMessage = $loginPickerForm.find('.jws-formErrorMessage'),
        $loginPickerButton = $loginPickerForm.find('.jwsdw-loginPicker-submit-container button'),
        $loginPickerButtonText = $loginPickerButton.find('.jwsdw-buttonText'),
        $loginPickerSpinner = $loginPickerButton.find('.jwsdw-spinner'),
        $registrationForm = $('form.jwsdw-register-form'),
        $registrationErrorMessage = $registrationForm.find('.jwsdw-register-errorMessage'),
        $registrationNotificationErrorMessage = $registrationForm.find('.jwsdw-notificationRegisterErrorMessage'),
        $registrationButton = $registrationForm.find('.jwsdw-accordionForm-listItem-submit'),
        $registrationButtonText = $registrationForm.find('.jwsdw-buttonText'),
        $registrationSpinner = $registrationForm.find('.jwsdw-spinner'),
        xhr;

    $loginButton.removeAttr('disabled');
    $registrationButton.removeAttr('disabled');

    $.validator.setDefaults({
        'rules': {
            'email': {
                'invalidvalue': true,
                'customemail': true
            }
        }
    });

    // initialize form validation
    $loginForm.validate();
    $loginPickerForm.validate();
    $registrationForm.validate();

    /**
     * Method to handle form submits
     * @param {Object} event the event that triggered the handler
     * @param {function} successCallback the callback that is triggered when the ajax call was successful
     * @param {function} errorCallback the callback that is triggered when the ajax call was unsuccessful
     * @param {function} beforeSendCallback the callback that is triggered before the ajax call is made
     * @param {function} completeCallback the callback that is triggered after the ajax call was made
     * @param {object} [fieldsToSend] optional fields that will be sent instead of the full form
     * @param {object} [validateCaptcha] optional field to trigger captcha validation
     * @returns {void}
     */
    function formSubmit (event, successCallback, errorCallback, beforeSendCallback, completeCallback, fieldsToSend, validateCaptcha) {
        var $this = $(this),
            $captcha = $this.find('.g-recaptcha');

        event.preventDefault();

        // only submit form if frontend validation is successful and no other call is executed
        if (!$this.valid() || (xhr && xhr.readyState !== 4)) {
            return;
        }

        if (window.jwsdwRecaptchaSettings.captchaDisabled === false &&
            validateCaptcha === true) {
            if (!window.grecaptcha || window.jwsdwUtil.captcha.getResponse($captcha.attr('id')).length !== 0) {
                return;
            }
            window.jwsdwUtil.captcha.execute($captcha.attr('id')).then(submitFormHandler);
        } else {
            submitFormHandler();
        }

        /**
         * @function
         * @description Handler to trigger submit again when form is valid
         * @return {void}
         */
        function submitFormHandler() {
            if (window.jwsdwRecaptchaSettings.captchaDisabled === false &&
                validateCaptcha === true && window.jwsdwUtil.captcha.getResponse($captcha.attr('id')).length === 0) {
                // no response yet, check again in 200ms
                setTimeout(submitFormHandler, 200);
                return;
            }

            // XHR request to form action url with form fields as parameters
            xhr = $.ajax($this.attr('action'), {
                'method': $this.attr('method') || 'GET',
                'data': !fieldsToSend ? $this.serialize() : fieldsToSend.serialize(),
                'beforeSend': beforeSendCallback,
                'success': successCallback,
                'error': errorCallback,
                'complete': completeCallback
            });
        }
    }

    /**
     * Method to execute if login was handled successfully
     * @param {object} data the response data
     * @param {String} data.returnUrl url for the account overview page to redirect the user
     * @returns {void}
     */
    function loginSuccessCallback (data) {
        window.dataLayer.push({
            'event':'ce.account',
            'eventCategory':'Account',
            'eventAction':'Login',
            'eventLabel':'Success',
            'eventValue':undefined,
            'nonInteraction':false
        });

        if (/getwallet/i.test(data.returnUrl)) {
            window.open(data.returnUrl);
            data.returnUrl = window.jwsdwSettings.baseUrl + '/Loyalty-showLoyaltyCardPage';
        }

        try {
            let url = new URL(data.returnUrl),
                hostname = url.hostname;

            if (hostname && hostname.includes(window.location.hostname)) {
                window.location.href = data.returnUrl;
            } else {
                window.location.href = window.jwsdwSettings.baseUrl + '/Home-Show';
            }
        } catch (error) {
            window.location.href = data.returnUrl;
        }
    }

    /**
     * Method to execute if login was not handled successfully
     * @param {object} data the response object containing the error
     * @returns {void}
     */
    function loginErrorCallback (data) {
        window.dataLayer.push({
            'event':'ce.account',
            'eventCategory':'Account',
            'eventAction':'Login',
            'eventLabel':'Error',
            'eventValue':undefined,
            'nonInteraction':false
        });

        $loginButton.removeAttr('disabled');
        $loginButtonText.removeClass('jwsdw-invisible');
        $loginSpinner.addClass('jws-hidden');

        // jquery ajax returns a wrapped result
        data = data.responseJSON ? data.responseJSON : {};

        if (data.code && data.code === 'AUTHENTICATION_FAILED') {
            if ($loginNotificationErrorMessage.length) {
                $loginNotificationErrorMessage.find('.jws-notificationMessage').text(data.details.message);
                $loginNotificationErrorMessage.removeClass('jws-hidden');
            } else {
                $loginErrorMessage.text(data.details.message);
                $loginErrorMessage.removeClass('jws-hidden');
            }
        }
    }

    /**
     * Method to execute the before send logic before the ajax call is made
     * @returns {void}
     */
    function loginBeforeSendCallback () {
        if ($loginNotificationErrorMessage.length) {
            $loginNotificationErrorMessage.addClass('jws-hidden');
        } else {
            $loginErrorMessage.addClass('jws-hidden');
        }
        $loginButton.attr('disabled', 'disabled');
        $loginButtonText.addClass('jwsdw-invisible');
        $loginSpinner.removeClass('jws-hidden');
    }

    /**
     * Method to execute after the  ajax call was made
     * @returns {void}
     */
    function loginCompleteCallback () {
        var $captcha;
        if (window.jwsdwRecaptchaSettings.captchaDisabled === false) {
            $captcha = $loginForm.find('.g-recaptcha');
            window.grecaptcha.reset(window.jwsdwRecaptcha[$captcha.attr('id')]);
        }
    }

    /**
     * Method to execute if login was handled successfully
     * @param {object} data the response data
     * @returns {void}
     */
    function loginPickerSuccessCallback (data) {
        window.dataLayer.push({
            'event':'ce.account',
            'eventCategory':'Account',
            'eventAction':'Login',
            'eventLabel':'Success',
            'eventValue':undefined,
            'nonInteraction':false
        });

        $loginPickerButton.removeAttr('disabled');
        $loginPickerButtonText.removeClass('jwsdw-invisible');
        $loginPickerSpinner.addClass('jws-hidden');

        window.jwsdwSettings.customerAuthenticated = true;
        window.jwsdwSettings.csrfTokenName = data.csrfToken.name;
        window.jwsdwSettings.csrfTokenValue = data.csrfToken.value;

        window.jwsdwMediator.publish('setCartCount', data.cartCount);
        window.jwsdwMediator.publish('setWishlistCount', data.wishlistCount);
        if (data.wishlist) {
            window.jwsdwMediator.publish('updateWishlist', data.wishlist);
        }

        window.jwsdwMediator.publish('closePicker', 'loginPicker', true);
        window.jwsdwLoginPicker.callback(data);
    }

    /**
     * Method to execute if login was not handled successfully
     * @param {object} data the response object containing the error
     * @returns {void}
     */
    function loginPickerErrorCallback (data) {
        window.dataLayer.push({
            'event':'ce.account',
            'eventCategory':'Account',
            'eventAction':'Login',
            'eventLabel':'Error',
            'eventValue':undefined,
            'nonInteraction':false
        });

        $loginPickerButton.removeAttr('disabled');
        $loginPickerButtonText.removeClass('jwsdw-invisible');
        $loginPickerSpinner.addClass('jws-hidden');

        // jquery ajax returns a wrapped result
        data = data.responseJSON ? data.responseJSON : {};

        if (data.code && data.code === 'AUTHENTICATION_FAILED') {
            $loginPickerErrorMessage.text(data.details.message);
            $loginPickerErrorMessage.removeClass('jws-hidden');
        }
    }

    /**
     * Method to execute the before send logic before the ajax call is made
     * @returns {void}
     */
    function loginPickerBeforeSendCallback () {
        $loginPickerErrorMessage.addClass('jws-hidden');
        $loginPickerButton.attr('disabled', 'disabled');
        $loginPickerButtonText.addClass('jwsdw-invisible');
        $loginPickerSpinner.removeClass('jws-hidden');
    }

    /**
     * Method to execute after the ajax call was made
     * @returns {void}
     */
    function loginPickerCompleteCallback () {
        var $captcha;
        if (window.jwsdwRecaptchaSettings.captchaDisabled === false) {
            $captcha = $loginPickerForm.find('.g-recaptcha');
            window.grecaptcha.reset(window.jwsdwRecaptcha[$captcha.attr('id')]);
        }
    }

    /**
     * Method to execute if registration was handled successfully
     * @returns {void}
     */
    function registrationSuccessCallback () {
        var $loginContainer = $('.jwsdw-login-container'),
            $registerContainer = $('.jwsdw-register-container'),
            $formContainer = $('.jwsdw-register-formContainer'),
            $registrationSuccessMessage = $('.jwsdw-register-successMessage'),
            $continueShoppingButton = $registrationSuccessMessage.find('.jwsdw-accordionForm-listItem-submit');

        window.dataLayer.push({
            'event':'ce.account',
            'eventCategory':'Account',
            'eventAction':'Registration',
            'eventLabel':'Success',
            'eventValue':undefined,
            'nonInteraction':false
        });

        $loginContainer.addClass('jws-hidden');
        $registerContainer.addClass('jws-grid-medium-shift-3 jws-grid-large-shift-2 jws-grid-large-column-8 jws-grid-xlarge-shift-1 jws-grid-xlarge-column-10');
        $continueShoppingButton.on('click', function () {
            $continueShoppingButton.attr('disabled', 'disabled');
            $continueShoppingButton.find('.jwsdw-buttonText').addClass('jwsdw-invisible');
            $continueShoppingButton.find('.jwsdw-spinner').removeClass('jws-hidden');
        });
        $registrationSuccessMessage.removeClass('jws-hidden');
        $formContainer.addClass('jws-hidden');
    }

    /**
     * Method to execute if registration was not handled successfully
     * @param {object} data the response object containing the error
     * @returns {void}
     */
    function registrationErrorCallback (data) {
        window.dataLayer.push({
            'event':'ce.account',
            'eventCategory':'Account',
            'eventAction':'Registration',
            'eventLabel':'Error',
            'eventValue':undefined,
            'nonInteraction':false
        });

        // jquery ajax returns a wrapped result
        data = data.responseJSON ? data.responseJSON : {};

        if (data.details && data.details.message) {
            if ($registrationNotificationErrorMessage.length) {
                $registrationNotificationErrorMessage.find('.jws-notificationMessage').text(data.details.message);
                $registrationNotificationErrorMessage.removeClass('jws-hidden');
            } else {
                $registrationErrorMessage.text(data.details.message);
                $registrationErrorMessage.removeClass('jws-hidden');
            }
        }
    }

    /**
     * Method to execute the before send logic before the ajax call is made
     * @returns {void}
     */
    function registrationBeforeSendCallback () {
        if ($registrationNotificationErrorMessage.length) {
            $registrationNotificationErrorMessage.addClass('jws-hidden');
        } else {
            $registrationErrorMessage.addClass('jws-hidden');
        }
        $registrationButton.attr('disabled', 'disabled');
        $registrationButtonText.addClass('jwsdw-invisible');
        $registrationSpinner.removeClass('jws-hidden');
    }

    /**
     * Method to execute after the ajax call was made
     * @returns {void}
     */
    function registrationCompleteCallback () {
        var $captcha;
        if (window.jwsdwRecaptchaSettings.captchaDisabled === false) {
            $captcha = $registrationForm.find('.g-recaptcha');
            window.grecaptcha.reset(window.jwsdwRecaptcha[$captcha.attr('id')]);
        }

        $registrationButton.removeAttr('disabled');
        $registrationButtonText.removeClass('jwsdw-invisible');
        $registrationSpinner.addClass('jws-hidden');
    }

    // login form submit listener
    $loginForm.submit(function (e) {
        formSubmit.call(this, e, loginSuccessCallback, loginErrorCallback, loginBeforeSendCallback, loginCompleteCallback, null, true);
    });
    // on change listener to remove error message
    $loginForm.on('input', 'input#jwsdw-login-mailAddress, input#jwsdw-login-password', function () {
        if ($loginNotificationErrorMessage.length) {
            $loginNotificationErrorMessage.addClass('jws-hidden');
        } else {
            $loginErrorMessage.addClass('jws-hidden');
        }
    });

    // login form submit listener
    $loginPickerForm.submit(function (e) {
        formSubmit.call(this, e, loginPickerSuccessCallback, loginPickerErrorCallback, loginPickerBeforeSendCallback, loginPickerCompleteCallback, null, true);
    });
    // on change listener to remove error message
    $loginPickerForm.on('input', 'input#jwsdw-loginPicker-mailAddress, input#jwsdw-loginPicker-password', function () {
        $loginPickerErrorMessage.addClass('jws-hidden');
    });


    // registration form submit listener
    $registrationForm.submit(function (e) {
        formSubmit.call(this, e, registrationSuccessCallback, registrationErrorCallback, registrationBeforeSendCallback, registrationCompleteCallback, null, true);
    });

    // on change listener to remove error message
    $registrationForm.on('input', 'input#jwsdw-register-mailAddress, input#jwsdw-register-password', function () {
        if ($registrationNotificationErrorMessage.length) {
            $registrationNotificationErrorMessage.addClass('jws-hidden');
        } else {
            $registrationErrorMessage.addClass('jws-hidden');
        }
    });
}(window, jQuery));
